import React, { useEffect, useState } from 'react'
import styles from './ApplicationProcessingStep.module.scss'
import ApplicationProcessingTimeItem from "components/UI/ApplicationProcessingTimeItem/ApplicationProcessingTimeItem";
import {FAILURE_PROTECTION, next, setInfo, setIsFormSubmitted, STATE_FEE, tariffs} from 'store/slices/visaSteps';
import { useDispatch, useSelector } from 'react-redux';
import { selectInfo, selectStepData } from 'store/selectors/visaSteps';
import {selectTariffs} from "../../../../store/selectors/catalog";
import {getTariffPrice, getTariffsFlight} from "../../../../utils/tariffService";
import {selectCurrency} from "../../../../store/selectors/header";

const ApplicationProcessingStep = () => {
    const {tariff} = useSelector(selectInfo)
    const [checkedItem, setCheckedItem] = useState(tariff?.id || 3)
    const currentCurrency = useSelector(selectCurrency);
    const tariffData = useSelector(selectTariffs);
    const bookingTiketTariffs = getTariffsFlight(tariffData);

    tariffs.length = 0;
    bookingTiketTariffs.map((item, index) => {
        if (item.tariff_type === 'VISA_ORDER') {
            tariffs.push({
                id: item.id,
                title: item.name,
                time: item.term,
                imgSrc: item.icon,
                price: getTariffPrice(currentCurrency?.code, item)
            })
        }
    });

    const dispatch = useDispatch()
    useEffect(() => {
        const tariff = tariffs.find(({ id }) => id === checkedItem)
        dispatch(setInfo({ tariff }))
    }, [checkedItem])

    const { isFormSubmitted } = useSelector(selectStepData);

    useEffect(() => {
        if (isFormSubmitted) {
            dispatch(next())
            dispatch(setIsFormSubmitted(false))
        }
    }, [isFormSubmitted]);

    return (
        <div className={styles.page}>
            <div className={styles.form}>
                {tariffs.map(tariff => (
                    <ApplicationProcessingTimeItem
                        key={tariff.id}
                        isChecked={checkedItem === tariff.id}
                        setIsChecked={() => setCheckedItem(tariff.id)}
                        className={styles.card}
                        {...tariff}
                        currentCurrency={currentCurrency}
                    />
                ))}
            </div>
        </div>
    )
}

export default ApplicationProcessingStep