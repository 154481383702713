import React, {useEffect, useState} from 'react'
import styles from './OrderDetailsStep.module.scss'
import Card from 'components/UI/Card/Card'
import RadioCard from 'components/UI/RadioCard/RadioCard'
import documentsSrc from 'assets/img/passports.png'
import fullShieldSrc from 'assets/icons/fullShield.png'
import Form from 'pages/VisaStepsPage/ui/Form/Form';
import Text from 'components/UI/Text/Text';
import documentIcon from 'assets/icons/document.svg'
import InfoItem from 'pages/VisaStepsPage/ui/InfoItem/InfoItem'
import calendarIcon from 'assets/icons/calendar-routes.svg'
import dotCalendarIcon from 'assets/icons/calendar/dot-calendar.svg'
import planeIcon from 'assets/icons/plane-ticket.svg'
import clockIcon from 'assets/icons/smallClock.svg'
import userIcon from 'assets/icons/blackUser.svg'
import {ReactSVG} from 'react-svg'
import {selectInfo, selectOrderData, selectStepData} from 'store/selectors/visaSteps'
import {useDispatch, useSelector} from 'react-redux'
import {FAILURE_PROTECTION, setFailure, setFees, setInfo, setIsFormSubmitted, STATE_FEE} from 'store/slices/visaSteps'
import {formatNumber} from 'utils/numberFn'
import ProtectionInfoModal from 'pages/VisaStepsPage/ui/ProtectionInfoModal/ProtectionInfoModal'
import {useNavigate} from 'react-router'
import {useOrderVisaMutation} from 'services/visaSteps'
import {message} from 'antd'
import {selectCurrency} from "../../../../store/selectors/header";
import {selectTariffs} from "../../../../store/selectors/catalog";
import {getTariffPrice, getTariffsFlight} from "../../../../utils/tariffService";
import {BASE_URL} from "../../../../api/constants";

const OrderDetailsStep = ({}) => {
    const [isOpen, setIsOpen] = useState('')
    const info = useSelector(selectInfo)
    const {is_visa, stay_of, passengers, countryTo, pass_valid, tariff, isProtected} = info
    const paymentCurrency = {
        RUB: "RUB",
        USD: "USD",
    };
    const getSum = () => STATE_FEE + (tariff?.price || 0)

    const tariffDescItems = [
        {
            desc: `Требования к визе`,
            iconSrc: documentIcon,
            value: is_visa ? 'Требуется' : 'Не требуется',
        },
        {
            desc: `Действительно`,
            iconSrc: calendarIcon,
            value: pass_valid,
        },
        {
            desc: `Максимальное пребывания`,
            iconSrc: dotCalendarIcon,
            value: stay_of,
        },
        {
            desc: 'Количество въездов',
            iconSrc: planeIcon,
            value: '',
        },
        {
            desc: 'Паспорт должен быть действителен',
            iconSrc: clockIcon,
            value: '',
        },
    ]

    const onLinkClick = (e) => {
        e.stopPropagation()
        setIsOpen(true)
    }

    const {isFormSubmitted} = useSelector(selectStepData);
    const orderData = useSelector(selectOrderData)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [orderVisa, result] = useOrderVisaMutation();
    const [messageApi, contextHolder] = message.useMessage();

    const currentCurrency = useSelector(selectCurrency);
    const tariffData = useSelector(selectTariffs);
    const bookingTiketTariffs = getTariffsFlight(tariffData);

    if (bookingTiketTariffs.length !== 0) {
        bookingTiketTariffs.map((item, index) => {
            if (item.tariff_type === 'VISA_GOVERNMENT_FEES_ORDER') {
                setFees(getTariffPrice(currentCurrency?.code, item))
            }
            if (item.tariff_type === 'VISA_FAILURE_PROTECTION') {
                setFailure(getTariffPrice(currentCurrency?.code, item))
            }
        })
    }

    useEffect(() => {
        if (isFormSubmitted) {
            orderVisa(orderData)
                .unwrap()
                //.then(() => {
                //    navigate('/');
                //})
                .catch((error) => {
                    messageApi.open({
                        type: 'error',
                        content: error?.data?.message,
                    });
                })
                .finally(() => dispatch(setIsFormSubmitted(false)))
        }
    }, [isFormSubmitted]);

    useEffect(() => {
        if (result?.data?.data?.message) return;
        if (result?.data?.data) {
            if (currentCurrency?.code === paymentCurrency.RUB)
                window.location.replace(`${BASE_URL}payments/tinkoff/redirect?id=` + result?.data?.data.id + `&order_type=2`)
            else
                window.location.replace(`${BASE_URL}payments/cloudpayments/redirect?id=` + result?.data?.data.id + `&order_type=2`)

        }
    }, [result.data]);
    return (
        <>
            {contextHolder}
            <Form className={styles.form}>
                <Card
                    imgSrc={documentsSrc}
                    imgAlt='passports'
                    title={`Электронная виза в ${countryTo.label}`}
                    subtitle={`${formatNumber(getSum())} ${currentCurrency?.symbol ?? ''}`}
                    subtitleNote=' за пассажира'
                    rightHeaderElement={<span className={styles.status_text}>{tariff?.title}</span>}
                >
                    {tariffDescItems.map(i => i.value ?
                        <InfoItem isSmall {...i} desc={`${i.desc}: ${i.value}`}/> : <></>)}
                    <Text variant='body16' isBold>Заявители</Text>
                    {passengers.map(i => (
                        <div key={JSON.stringify(i)} className={styles.user}>
                            <ReactSVG src={userIcon}/>
                            <Text>{i.firstName} {i.lastName}</Text>
                        </div>
                    ))}
                </Card>
                <RadioCard setIsChecked={() => dispatch(setInfo({isProtected: !isProtected}))} isChecked={isProtected}
                           title='Защита от отказа' subtitle={`${FAILURE_PROTECTION} ${currentCurrency?.symbol ?? ''}`}
                           imgSrc={fullShieldSrc} imgAlt='shield'>
                    <Text variant='body14'>Получите 100% возврат средств по вашему заказу, если ваше заявление будет
                        отклонено правительством по какой-либо причине.</Text>
                    <span className={styles.link} onClick={onLinkClick}>Узнать больше</span>
                </RadioCard>
            </Form>
            <ProtectionInfoModal isOpen={isOpen} setIsOpen={setIsOpen}/>
        </>
    )
}

export default OrderDetailsStep
