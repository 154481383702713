import { createSelector } from "@reduxjs/toolkit";
import { formatDate } from "utils/dateFn";

const selectVisaSteps = (state) => state.visaSteps;

export const selectStepData = createSelector(
    [selectVisaSteps],
    (data) => data
);

export const selectItemData = createSelector(
    [selectVisaSteps],
    (data) => data.currentStepData[data.currentItemNumber]
);

export const selectFinishedItemsAmount = createSelector(
    [selectVisaSteps],
    (data) => data.finishedItemsAmount
);

export const selectInfo = createSelector(
    [selectVisaSteps],
    (data) => data.info
);

export const selectOrderData = createSelector(
    [selectVisaSteps],
    (data) => {
        if (data.finishedItemsAmount !== data.allItemsAmount) return {}

        const formattedData = (passenger, i) => {
            return ({
                firstname: passenger.firstName,
                lastname: passenger.lastName,
                birthday: formatDate(passenger.birthDate),
                country: passenger.residenceCountry?.value,
                gender: passenger.gender === 'Женский' ? 'F' : 'M',
            })
        };

        const { info } = data

        return {
            arrival_date: info.arrivalDate,
            departure_date: info.departureDate,
            citizenship_id: info.citizenship.id,
            country_to_id: info.countryTo.id,
            failure_protection_id: Number(info.isProtected),
            tariff_id: info.tariff?.id,
            price: data.price.toString(),
            passengers: info.passengers.map((passenger, i) => ({
                ...formattedData(passenger),
                document_expires: !i && !info.skipFields ? info?.expiryDate : '',
                document_number: !i && !info.skipFields ? info?.documentNumber : '',
                document_type: !i && !info.skipFields ? info?.documentType : ''
            })),
            // send_promo: ,
            email: info.email,
            phone_prefix: info.phone_code,
            // phone: ,
            // comments: ,
        }
    }
);