import styles from "./PaymentAside.module.scss";
import CustomButton from "components/UI/CustomButton/CustomButton";
import {ReactSVG} from "react-svg";
import file from "assets/icons/fileComplete.svg";
import clock from "assets/icons/smallClock.svg";
import profile from "assets/icons/profile-passengers-icon.svg";
import balance from "assets/icons/profile-balance-icon.svg";
import shield from "assets/icons/shield.svg";
import plane from "assets/icons/plane-nav.svg";
import dotCalendar from "assets/icons/calendar/dot-calendar.svg";
import simpleFile from "assets/icons/file/simple-file.svg";
import WarningIcon from "assets/icons/warning-icon.svg";
import InfoItem from '../InfoItem/InfoItem'
import {useDispatch, useSelector} from "react-redux";
import {
    FAILURE_PROTECTION,
    next, setFailure,
    setFees,
    setIsFormSubmitted,
    setVisaFeesOrder,
    STATE_FEE
} from 'store/slices/visaSteps'
import Title from "components/UI/Title/Title";
import {selectInfo, selectStepData} from "store/selectors/visaSteps";
import {memo, useEffect, useState} from "react";
import Spinner from "components/UI/Spinner/Spinner";
import {selectCurrency} from "store/selectors/header";
import {selectTariffs} from "store/selectors/catalog";
import {getTariffPrice, getTariffsFlight} from "utils/tariffService";
import {Skeleton} from "antd";

const InfoAside = memo(() => {
    const {is_visa, stay_of, link, countryTo, pass_valid, isLoading, citizenship} = useSelector(selectInfo)

    if (isLoading) {
        return <Skeleton className={styles.payment} loading={isLoading} active paragraph={{rows: 4}}/>;
    }

    return (
        <aside className={styles.payment}>
            <Title Tag="h5" className={styles.payment__title}>Электронная виза для граждан страны {citizenship?.label} в страну {countryTo?.label}.</Title>
            <p className={styles.divider}></p>
            <div className={styles.payment__items}>
                <InfoItem
                    name='Требования к визе'
                    desc={is_visa ? 'Требуется' : 'Не требуется'}
                    iconSrc={simpleFile}
                />
                {stay_of && <InfoItem
                    name='Максимальное пребывание'
                    desc={stay_of}
                    iconSrc={dotCalendar}
                />}
                {false && <InfoItem
                    name='Количество въездов'
                    desc=''
                    iconSrc={plane}
                />}
                {pass_valid && <InfoItem
                    name='Паспорт должен быть действителен'
                    desc={pass_valid}
                    iconSrc={clock}
                />}
                {link && <InfoItem
                    name='Официальный сайт'
                    desc={new URL(link).origin}
                    href={link}
                    iconSrc={file}
                    isLink
                />}
            </div>
        </aside>
    )
})

const ResultAside = memo(() => {
    const {finishedItemsAmount, info, price, isFormSubmitted, allItemsAmount} = useSelector(selectStepData);
    const {countryTo, citizenship} = useSelector(selectInfo)
    const dispatch = useDispatch();
    const currentCurrency = useSelector(selectCurrency)
    const isLast = finishedItemsAmount === allItemsAmount
    const tariffData = useSelector(selectTariffs);

    const bookingTiketTariffs = getTariffsFlight(tariffData);

    if (bookingTiketTariffs.length !== 0) {
        bookingTiketTariffs.map((item, index) => {
            if (item.tariff_type === 'VISA_GOVERNMENT_FEES_ORDER') {
                setFees(getTariffPrice(currentCurrency?.code, item))
            }
            if (item.tariff_type === 'VISA_FAILURE_PROTECTION') {
                setFailure(getTariffPrice(currentCurrency?.code, item))
            }
        })
    }

    const onNext = () => {
        dispatch(setIsFormSubmitted(true))
    };

    return (
        <aside className={styles.payment}>
            <Title Tag="h5">Электронная виза для граждан страны {citizenship?.label} в страну {countryTo?.label}.</Title>
            <div className={styles.payment__items}>
                {finishedItemsAmount >= 3 && <InfoItem
                    desc={`${info.passengers?.length} заявитель`}
                    iconSrc={profile}
                />}
                {finishedItemsAmount >= 1 && <InfoItem
                    desc='+Государственные сборы'
                    iconSrc={balance}
                    price={STATE_FEE}
                    currentCurrency={currentCurrency}
                />}
                {finishedItemsAmount >= 4 && info.tariff && <InfoItem
                    desc={`${info.tariff.title}, ${info.tariff.time} дней`}
                    iconSrc={clock}
                    price={info.tariff.price}
                    currentCurrency={currentCurrency}
                />}
                {finishedItemsAmount >= 5 && info?.isProtected && <InfoItem
                    desc={`Защита от отказа`}
                    iconSrc={shield}
                    price={FAILURE_PROTECTION}
                    currentCurrency={currentCurrency}
                />}
            </div>
            <p className={styles.divider}></p>
            <div className={styles.payment__items}>
                <InfoItem
                    desc='Итого'
                    note='Для всех путешественников'
                    price={finishedItemsAmount >= 4 ? price : ''}
                    currentCurrency={currentCurrency}
                />
                <InfoItem
                    desc='Рассчитывается при оформлении заказа'
                    note='(Включая налоги и сборы)'
                />
                <CustomButton
                    primary={true}
                    fullWidth={true}
                    onClick={onNext}
                    type={"submit"}
                    disabled={isFormSubmitted}
                >
                    {isFormSubmitted ? <Spinner /> : isLast ? `К оплате в ${currentCurrency?.code??''}` : 'Сохранить и продолжить'}
                </CustomButton>
                {isLast && (
                    <div className={styles.payment__title5}>
                        {currentCurrency?.code === 'USD' ? (
                            <>
                                * Для оплаты картой РФ выберите валюту “RUB“
                            </>
                        ) : (
                            <>
                                * Для оплаты иностранной картой выберите валюту “USD“
                            </>
                        )}
                    </div>
                )}
                <div className={styles.warning}>
                    <ReactSVG src={WarningIcon}/>
                    <div>
                        <p>С нами ваша информация в безопасности!</p>
                        <div className={styles.info}>
                            <p className={styles.info_text}>
                                Более подробную информацию см.
                            </p>
                            <a className={styles.info_link} href={"/privacyPolicy"} target={"_blank"}>
                                в заявлении AirSurfer о безопасности данных.
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    )
})

const PaymentAside = memo(({
                               type
                           }) => {
    if (type === 'info') return <InfoAside/>
    if (type === 'result') return <ResultAside/>
    return <></>
});

export default PaymentAside;
