import { createSlice } from "@reduxjs/toolkit";
import profileIcon from 'assets/icons/profile.svg'
import calendarIcon from 'assets/icons/calendar-routes.svg'
import standardSrc from 'assets/img/rates/standard.png'
import plusSrc from 'assets/img/rates/plus.png'
import expressSrc from 'assets/img/rates/express.png'

export let STATE_FEE = 9373.51
export let FAILURE_PROTECTION = 1405.09

export const setFees = (fees) => {
    STATE_FEE = fees
};

export const setFailure = (failure) => {
    FAILURE_PROTECTION = failure
};

export const tariffs = [
    /*{
        id: 1,
        title: 'Стандарт',
        time: 10,
        imgSrc: standardSrc,
        price: 8651.08
    },
    {
        id: 2,
        title: 'Плюс',
        time: 6,
        imgSrc: plusSrc,
        price: 11853.27
    },
    {
        id: 3,
        title: 'Экспресс',
        time: 5,
        imgSrc: expressSrc,
        price: 16571.53
    },*/
]

export const stepsData = [
    [
        {
            title: 'Подробности поездки',
        }
    ],
    [
        {
            titleIcon: profileIcon,
            title: '1 заявитель',
            subtitle: 'Введите данные заявителя',
        },
        {
            title: 'Ваши паспортные данные',
        },
        {
            title: 'Время обработки заявления',
            subtitle: 'Выберите время обработки заявления',
        },
    ],
    [
        {
            subtitleIcon: calendarIcon,
            title: 'Детали заказа',
            subtitle: 'Заполните заявку сейчас и получите ее до DATE',
        }
    ]
]

const localStorageSetState = (state) => {
    return {...JSON.parse(localStorage.getItem('visaStepsState')), isFormSubmitted: false} || initialState
}

const initialState = {
    currentStepNumber: 0,
    currentItemNumber: 0,
    currentStepData: { ...stepsData[0] },
    finishedItemsAmount: 0,
    allItemsAmount: stepsData.reduce((acc, items) => acc + items.length, 0),
    isFormSubmitted: false,
    info: {},
    price: 0
};

const localStorageGetState = () => {
    return JSON.parse(localStorage.getItem('visaStepsState')) || initialState
}

export const visaStepsSlice = createSlice({
    name: "visa-steps",
    initialState: localStorageGetState(),
    reducers: {
        resetSteps: (state) => {
            localStorageSetState({ ...initialState, info: { countryTo: state?.countryTo || null, citizenship: state?.citizenship || null } });
            return initialState;
        },
        next: (state) => {

            if (state.currentStepData?.length) {
                state.currentItemNumber++;
                if (state.currentItemNumber !== state.currentStepData.length) {
                    state.finishedItemsAmount++;
                    localStorageSetState(state);
                    return
                }
            }

            state.currentStepNumber++;
            state.currentItemNumber = 0;
            state.currentStepData = stepsData[state.currentStepNumber - 1]
            state.finishedItemsAmount = stepsData.slice(0, state.currentStepNumber - 1).reduce((acc, items) => acc + items.length, 0) + 1;
            localStorageSetState(state);
        },
        back: (state) => {
            console.log('state', JSON.stringify(state))
            if (state.currentItemNumber > 0) {
                state.currentItemNumber--;
            }
            else if (state.currentStepNumber > 1) {
                state.currentStepNumber--;
                state.currentStepData = stepsData[state.currentStepNumber - 1];
                state.currentItemNumber = state.currentStepData.length - 1;
            }
            else if (state.finishedItemsAmount === 1) {
                state.currentStepNumber = 0;
                state.currentItemNumber = 0;
                state.currentStepData = { ...stepsData[0] };
                state.finishedItemsAmount = 0
                return
            }
            state.finishedItemsAmount--
            localStorageSetState(state);
        },
        goToStep: (state, { payload }) => {
            state.currentStepNumber = payload + 1;
            state.currentItemNumber = 0;
            state.currentStepData = stepsData[state.currentStepNumber - 1];
            state.finishedItemsAmount = stepsData.slice(0, state.currentStepNumber - 1).reduce((acc, items) => acc + items.length, 0) + 1;
            localStorageSetState(state);
        },
        setInfo: (state, { payload }) => {
            state.info = { ...state.info, ...payload }
            if(state.info.tariff?.price && state.info.passengers) state.price = (STATE_FEE + (state.info.tariff?.price || 0)) * state.info.passengers?.length + (state.info?.isProtected ? FAILURE_PROTECTION : 0)
            localStorageSetState(state)
        },
        setIsFormSubmitted: (state, { payload }) => {
            state.isFormSubmitted = payload
        },
    },
});

export const { next, resetSteps, setInfo, back, goToStep, setIsFormSubmitted } = visaStepsSlice.actions;

export default visaStepsSlice.reducer;
