import styles from "./Paid.module.scss";
import React from "react";
import done from "../../../../assets/icons/done.png";
const Paid = ({
                  email,
                  routeLinkFlight,
                  routeLinkHotel,
                  flightBookingFile,
                  hotelBookingFile,
                  isOrderWithHotel,
                  orderRenewal,
                  flightToBookingExpiresAt,
                  tariff,
                  orderId,
                  orderRenewalDay
              }) => {

    return (
        <div className={styles.VisaPaymentSuccess__top}>
            <img
                className={styles.VisaPaymentSuccess__top_img}
                src={done}
                alt="done"
            />
            <h1 className={styles.VisaPaymentSuccess__top_title}>
                Оформление визы успешно оплачено!
            </h1>
            <p className={styles.VisaPaymentSuccess__top_text}>
                Дальнейшая информация будет отправлена на почту.
                <br/>
                {" "}
                <span>{email}</span>
            </p>
        </div>
    );
};

export default Paid;
