import { Radio } from 'components/UI/Radio/Radio'
import styles from './ApplicationProcessingTimeItem.module.scss'
import { ReactSVG } from "react-svg";
import timeIcon from 'assets/icons/smallClock.svg'
import classNames from 'classnames';
import { formatNumber } from 'utils/numberFn';

const ApplicationProcessingTimeItem = ({ imgSrc, isChecked, setIsChecked, title, price, time, className,currentCurrency }) => {
    return <div className={classNames([styles.wrapper, {[styles.checked]: isChecked}, className])} onClick={setIsChecked}>
        <div className={styles.header}>
            <img className={styles.src} src={imgSrc} alt="rate" />
            <Radio checked={isChecked} />
        </div>
        <div className={styles.body}>
            <div className={styles.rate}>
                <p className={styles.title}>{title}</p>
                <p className={styles.price}>{formatNumber(price)} {currentCurrency.symbol} <span>за пассажира</span></p>
            </div>
            <div className={styles.timeWrapper} >
                <div className={styles.icon}>
                    <ReactSVG src={timeIcon} width={20} height={20} />
                </div>
                <p className={styles.time}>
                    {time} дней
                </p>
            </div >
        </div>
    </div>
}

export default ApplicationProcessingTimeItem