import style from "./Header.module.scss";
import Button from "../UI/Button/Button";
import List from "../UI/List/List";
import { menu1, menu2 } from "../../root/menu";
import headerProfile from "../../assets/icons/headerProfile.svg";
import clsx from "clsx";
import { ReactSVG } from "react-svg";
import Menu from "../UI/Menu/Menu";
import React, { useEffect, useRef, useState } from "react";
import avatar from "../../assets/icons/avatar.svg";
import Dropdown from "../UI/Dropdown/Dropdown";
import ProfileMenu from "../../pages/Profile/ProfileMenu/ProfileMenu";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "../UI/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { setCurrency, setOpen } from "../../store/slices/headerSlice";
import useIsMobile from "../../hooks/useIsMobile";
import Popups from "./Popups";
import { useGetUserQuery } from "../../services/user";
import { setProfile, setToken, setUser } from "../../store/slices/user";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useGetCatalogQuery } from "../../services/catalog";
import {
    setCatalog,
    setCurrencies,
    setTariffs,
} from "../../store/slices/catalog";
import { setModalOpen } from "../../store/slices/headerSlice";
import { selectCurrency, selectLang } from "store/selectors/header";
import { cookieKeys, getCookie } from "utils/cookie";
import { TranslateWidget } from "../TranslateWidget/TranslateWidget";
import { getBrowserlang } from "services/lang";

const Header = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const bearer_token = urlParams.get("bearer_token");
    const activeCurrency = useSelector(selectCurrency);
    const promo = urlParams.get("promo");
    const currentLang = useSelector(selectLang);

    //Выбор валюты
    const [isCurrencyModalVisible, setCurrencyModalVisible] = useState(false);

    //Выбор валюты
    const [isLangModalVisible, setLangModalVisible] = useState(false);

    const isModalOpened = useSelector(
        (state) => state.headerSlice.isModalOpened
    );

    const setLoginModalVisible = (mode) => {
        dispatch(setModalOpen(mode));
    };

    // const [isLoginModalVisible, setLoginModalVisible] = useState(false);

    const [isProfileMenuVisible, setProfileMenuVisible] = useState(false);

    const dispatch = useDispatch();

    let token = useSelector((state) => state.user.token);

    const navigate = useNavigate();

    if (bearer_token) {
        localStorage.setItem("token", bearer_token);
        dispatch(setToken({ token: bearer_token }));
        token = bearer_token;
        const pathname = localStorage.getItem("redirectUrl");
        navigate(pathname);
    }

    if (promo) {
        localStorage.setItem("promo", promo);
    }

    const { data } = useGetUserQuery({}, { skip: !token });
    const { data: catalog } = useGetCatalogQuery();

    const isOpened = useSelector((state) => state.headerSlice.isOpened);
    const user = useSelector((state) => state.user.user);

    const isMobile = useIsMobile();

    const wrapperRef = useRef(null);
    const buttonRef = useRef(null);

    useOutsideClick(
        wrapperRef,
        () => {
            setProfileMenuVisible(false);
        },
        buttonRef
    );

    useEffect(() => {
        if (data) {
            dispatch(setUser({ user: data.data.user }));
            dispatch(setProfile({ profile: data.data.profile }));
        }
    }, [data]);

    useEffect(() => {
        if (catalog) {
            const { currencies, tariffs } = catalog.data;
            const newCatalog = catalog.data.catalog;
            let cookieCurrencyKey = getCookie(cookieKeys.currency);
            if (!cookieCurrencyKey) {
                let lang = getBrowserlang();
                if (lang == "EN") cookieCurrencyKey = "USD";
            }
            dispatch(setCatalog({ catalog: newCatalog }));
            if (currencies && currencies.length) {
                dispatch(setCurrencies({ currencies }));
                if (cookieCurrencyKey && cookieCurrencyKey != "undefined") {
                    const currentCurrency = currencies.find(
                        (item) => item.code == cookieCurrencyKey
                    );
                    if (currentCurrency) dispatch(setCurrency(currentCurrency));
                    else dispatch(setCurrency(currencies[0]));
                } else dispatch(setCurrency(currencies[0]));
            }
            if (tariffs) dispatch(setTariffs({ tariffs }));
        }
    }, [catalog]);

    const handleProfileMenuClick = () => {
        if (token) {
            setProfileMenuVisible(true);
        } else {
            setLoginModalVisible(true);
        }
    };

    const handleHeaderClick = () => {
        dispatch(setOpen(!isOpened));
    };

    return (
        <header className={style.header}>
            <div className="container">
                <nav className={clsx(style.upheader, style.upheader__desktop)}>
                    <Button
                        type={"Link"}
                        url={"/"}
                        imgLeftContainer={style.upheader__logo_img}
                        imgRightContainer={style.upheader__logo_text}
                        container={style.upheader__logo}
                    />
                    <div className={style.upheader__btns}>
                        <Button
                            imgLeftContainer={style.button__active__img_plane}
                            container={style.button__active}
                            text={"Авиабилеты и отели для визы"}
                        />
                        <Button
                            imgLeftContainer={style.button__img_doc}
                            container={style.button__disabled}
                            text={"Документы для визы"}
                            //imgRightContainer={style.button__disabled_info}
                        />
                        <Button
                            imgLeftContainer={style.button__img_heart}
                            container={style.button__disabled}
                            imgRightContainer={style.button__disabled_info}
                            text={"Страхование за границей"}
                        />
                    </div>
                    <List array={menu2} container={style.upheader__links} />
                </nav>
                {!isProfileMenuVisible && (
                    <nav
                        className={clsx(style.upheader, style.upheader__mobile)}
                    >
                        <Button
                            type={"Link"}
                            url={"/"}
                            imgLeftContainer={style.upheader__logo_img}
                            imgRightContainer={style.upheader__logo_text}
                            container={style.upheader__logo}
                        />
                        {activeCurrency && currentLang == "RU" ? (
                            <Button
                                container={style.button__currency}
                                text={activeCurrency.code}
                                onClick={() => {
                                    setCurrencyModalVisible(true);
                                }}
                                ref={buttonRef}
                            />
                        ) : (
                            <div className={style.button__currency}></div>
                        )}
                        <TranslateWidget
                            onOpenModal={() => {
                                setLangModalVisible(true);
                            }}
                        />
                        <div className={style.upheader__profile}>
                            <div className={style.upheader__profile_btn}>
                                <ReactSVG
                                    onClick={handleProfileMenuClick}
                                    src={headerProfile}
                                />
                            </div>
                        </div>
                        <div className={style.upheader__menu}>
                            <Menu>
                                <div className={style.upheader__menu_elem}>
                                    <Dropdown
                                        isMobile={true}
                                        isColumn={true}
                                        opened={false}
                                        classes={style.dropdown}
                                        containerClass={
                                            style.dropdown__container
                                        }
                                        titleChildren={
                                            <Button
                                                imgLeftContainer={
                                                    style.button__active__img_plane
                                                }
                                                container={clsx(
                                                    style.button__active,
                                                    style.button__active_mobile
                                                )}
                                                text={
                                                    "Авиабилеты и отели для визы"
                                                }
                                            />
                                        }
                                    >
                                        <Link
                                            to="/"
                                            className={
                                                style.upheader__menu_item
                                            }
                                            onClick={handleHeaderClick}
                                        >
                                            Авиабилеты и отели для визы
                                        </Link>
                                        <Link
                                            to="/faq"
                                            className={
                                                style.upheader__menu_item
                                            }
                                            onClick={handleHeaderClick}
                                        >
                                            Вопросы и ответы
                                        </Link>
                                        <a
                                            className={
                                                style.upheader__menu_item
                                            }
                                            href="https://www.trustpilot.com/review/airsurfer.co"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Отзывы
                                        </a>
                                        <Link
                                            to="/products"
                                            className={
                                                style.upheader__menu_item
                                            }
                                            onClick={handleHeaderClick}
                                        >
                                            Тарифы
                                        </Link>
                                        <Link
                                            to="/partner"
                                            className={
                                                style.upheader__menu_item
                                            }
                                            onClick={handleHeaderClick}
                                        >
                                            Партнерская программа
                                        </Link>
                                    </Dropdown>
                                </div>
                                <div className={style.upheader__menu_elem}>
                                    <Button
                                        imgLeftContainer={
                                            style.button__img_heart
                                        }
                                        imgRightContainer={
                                            style.button__disabled_info
                                        }
                                        container={style.button}
                                        text={"Страхование"}
                                    />
                                </div>
                                <div className={style.upheader__menu_elem}>
                                    <Button
                                        imgLeftContainer={style.button__img_doc}
                                        container={style.button__disabled}
                                        text={"Документы для визы"}
                                        /*imgRightContainer={
                                            style.button__disabled_info
                                        }*/
                                    />
                                </div>
                                <div className={style.menu__block}>
                                    <a href="mailto:support@airsurfer.co">
                                        support@airsurfer.co
                                    </a>
                                    <div className={style.menu__block_link}>
                                        <CustomButton
                                            onClick={() => {
                                                if (user) {
                                                    navigate(
                                                        "/profile/settings"
                                                    );
                                                    dispatch(setOpen(false));
                                                } else {
                                                    setLoginModalVisible(true);
                                                }
                                            }}
                                            lined
                                            fullWidth
                                        >
                                            <ReactSVG src={avatar} />
                                            {user ? "Профиль" : "Войти"}
                                        </CustomButton>
                                        <Popups
                                            isLangModalVisible={
                                                isLangModalVisible
                                            }
                                            setLangModalVisible={
                                                setLangModalVisible
                                            }
                                            isLoginModalVisible={isModalOpened}
                                            isCurrencyModalVisible={
                                                isCurrencyModalVisible
                                            }
                                            setCurrencyModalVisible={
                                                setCurrencyModalVisible
                                            }
                                            setLoginModalVisible={
                                                setLoginModalVisible
                                            }
                                        />
                                    </div>
                                </div>
                            </Menu>
                        </div>
                    </nav>
                )}
                {isProfileMenuVisible && isMobile && (
                    <ProfileMenu onClick={() => setProfileMenuVisible(false)} />
                )}
                <div
                    className={clsx(style.downheader, style.upheader__desktop)}
                >
                    <List array={menu1} container={style.downheader__nav} />

                    {activeCurrency && currentLang == "RU" ? (
                        <Button
                            container={style.button__currency}
                            text={activeCurrency.code}
                            onClick={() => {
                                setCurrencyModalVisible(true);
                            }}
                            ref={buttonRef}
                        />
                    ) : (
                        <div className={style.button__currency}></div>
                    )}
                    <TranslateWidget
                        onOpenModal={() => {
                            setLangModalVisible(true);
                        }}
                    />
                    <Button
                        imgLeftContainer={style.button__signin_icon}
                        container={style.button__signin}
                        text={user ? "Профиль" : "Войти"}
                        onClick={() => {
                            user
                                ? isProfileMenuVisible
                                    ? setProfileMenuVisible(false)
                                    : setProfileMenuVisible(true)
                                : setLoginModalVisible(true);
                        }}
                        ref={buttonRef}
                    />
                    {isProfileMenuVisible && !isMobile && (
                        <ProfileMenu
                            ref={wrapperRef}
                            dropdown
                            onClick={() => setProfileMenuVisible(false)}
                        />
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
