import {useFormik} from "formik";
import styles from "./VisaPanel.module.scss";
import VisaDropdownCountry from "../../../components/UI/VisaDropdownCountry/VisaDropdownCountry";
import CustomButton from "components/UI/CustomButton/CustomButton";
import {useCheckVisaQuery, useGetCountriesQuery} from "services/visaSteps";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {ROUTES} from "api/constants";
import {useDispatch, useSelector} from "react-redux";
import {resetSteps, setInfo} from "store/slices/visaSteps";
import Spinner from "components/UI/Spinner/Spinner";
import {selectInfo} from "store/selectors/visaSteps";

const validate = (values) => {
    const errors = {};

    if (!values.citizenship?.value) {
        errors.citizenship = "Гражданство обязательно";
    }

    if (!values.countryTo?.value) {
        errors.countryTo = "Страна поездки обязательна";
    }

    if (values.citizenship?.value === values.countryTo?.value) {
        errors.citizenship = errors.countryTo = "Страна должны быть разными";
    }

    return errors;
};

const defaultOption = {
    label: "Россия",
    value: "RU",
    code: "RU",
    name_en: "Россия",
    name_ru: "Россия",
    id: 20,
};

const defaultOptionTo = {
    label: "Сингапур",
    value: "SG",
    code: "SG",
    name_en: "Сингапур",
    name_ru: "Сингапур",
    id: 42,
}

const defaultOptionToArr = [
    {label: "Сингапур", name_ru: "Сингапур", value: "SG", code: 'SG', id: 42},
    {label: "Южная корея", name_ru: "Южная корея", value: "KR", code: "KR", id: 3},
    {label: "Индия", name_ru: "Индия", value: "IN", code: "IN", id: 6},
    {label: "Индонезия", name_ru: "Индонезия", value: "ID", code: "ID", id: 45},
]

const VisaPanel = () => {
    const serverValue = defaultOption//useGetCountriesQuery('Россия')?.data?.data?.[0];
    const serverValueTo = defaultOptionTo//useGetCountriesQuery('Сингапур')?.data?.data?.[0];
    const defaultValue = {...serverValue, label: serverValue?.name_ru, value: serverValue?.code}
    const defaultValueTo = {...serverValueTo, label: serverValueTo?.name_ru, value: serverValueTo?.code}
    const [resultParams, setResultParams] = useState(null);
    const {citizenship: citizenshipDefault, countryTo: countryToDefault} = useSelector(selectInfo)
    const result = useCheckVisaQuery(resultParams);
    const [citizenship, setCitizenship] = useState(citizenshipDefault || defaultValue)
    const [countryTo, setCountryTo] = useState(countryToDefault || defaultValueTo)

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetSteps());
    }, []);

    const formik = useFormik({
        initialValues: {
            citizenship,
            countryTo,
        },
        validate,
        validateOnChange: false,
        onSubmit: (values) => {
            setResultParams({
                citizenship: values.citizenship?.value || defaultValue,
                countryTo: values.countryTo?.value || defaultValueTo,
            });
        },
    });

    useEffect(() => {
        if (result?.data?.data?.message) return;
        if (result?.data?.data) {
            dispatch(
                setInfo({
                    ...result?.data?.data,
                    citizenship,
                    countryTo,
                })
            );

            navigate(
                ROUTES.STEPS
                    .replace(':params', `${citizenship.code.toLowerCase()}-${countryTo.code.toLowerCase()}`),
            )

        }
    }, [result.data]);

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
            <VisaDropdownCountry
                onSearch={(val) => formik.setFieldValue("citizenship", val)}
                value={formik.values.citizenship}
                label={"Гражданство"}
                containerClasses={styles.select}
                classes={styles.width}
                onChange={(val) => formik.setFieldValue("citizenship", val)}
                error={formik.errors.citizenship || result?.data?.data?.message || result?.error?.data?.message || ''}
                getFullInfo={setCitizenship}
                key='citizenship'
            />
            <VisaDropdownCountry
                onSearch={(val) => formik.setFieldValue("countryTo", val)}
                value={formik.values.countryTo}
                label={"Поездка в страну"}
                containerClasses={styles.select}
                classes={styles.width}
                onChange={(val) => formik.setFieldValue("countryTo", val)}
                error={formik.errors.countryTo || result?.data?.data?.message || result?.error?.data?.message || ''}
                getFullInfo={setCountryTo}
                key='countryTo'
                setOptions={defaultOptionToArr}
            />
            {
                <CustomButton
                    classes={styles.button}
                    type="submit"
                    secondary
                >
                    {result?.isFetching ? <Spinner/> : 'Найти'}
                </CustomButton>
            }
        </form>
    );
};

export default VisaPanel;
